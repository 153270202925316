import React from "react";
import './WithLove.css'

export default function WithLove() {
    return (
        <div className="aishiteru">
    
                Made With ❤️ by <a href="https://www.devsoc.club">DevSoc</a>
        
        </div>

    )
}